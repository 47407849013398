import React from 'react';

const paths = [
  [0, 878, 'c-5 -7 -7 -26 -3 -43 6 -30 6 -30 13 9 7 44 5 53 -10 34z'],
  [
    0,
    866,
    'c-14 -18 -6 -50 13 -44 15 6 22 37 10 49 -6 6 -15 4 -23 -5z m20 -21 c0 -8 -4 -15 -10 -15 -5 0 -10 7 -10 15 0 8 5 15 10 15 6 0 10 -7 10 -15z',
  ],
  [0, 870, 'c-3 -5 -2 -10 4 -10 5 0 12 -10 14 -22 4 -20 4 -20 6 4 1 28 -13 45 -24 28z'],
  [
    0,
    866,
    'c-14 -18 -6 -50 13 -44 15 6 22 37 10 49 -6 6 -15 4 -23 -5z m16 -22 c-4 -9 -9 -15 -11 -12 -3 3 -3 13 1 22 4 9 9 15 11 12 3 -3 3 -13 -1 -22z',
  ],
  [0, 865, 'c-3 -8 -1 -15 5 -15 6 0 9 -7 5 -15 -3 -8 -1 -15 4 -15 6 0 10 14 10 30 0 32 -14 40 -24 15z'],
  [
    0,
    871,
    'c-19 -11 -11 -45 11 -49 9 -2 20 4 23 13 9 21 -16 47 -34 36z m26 -22 c-2 -6 -8 -10 -13 -10 -5 0 -11 4 -13 10 -2 6 4 11 13 11 9 0 15 -5 13 -11z',
  ],
  [0, 873, 'c-3 -4 -2 -19 2 -33 l6 -25 7 25 c6 24 -6 51 -15 33z'],
  [
    0,
    866,
    'c-14 -18 -6 -50 13 -44 15 6 22 37 10 49 -6 6 -15 4 -23 -5z m20 -22 c0 -8 -5 -12 -10 -9 -6 4 -8 11 -5 16 9 14 15 11 15 -7z',
  ],
];

const rand = (min, max) => min + Math.round(Math.random() * (max - min));
const rand255 = (min, max) => Math.max(0, Math.min(255, min + Math.round(Math.random() * (max - min))));
const BASE_Y = 880;
const SIZE_X = 50;
const SIZE_Y = 70;

const INITIAL_R = 248;
const INITIAL_G = 208;
const INITIAL_B = 70;
const COLOR_VARIANCE = 180;

export default class Bits extends React.Component {
  static defaultProps = {
    x: 50,
    y: 10,
  };

  componentWillMount() {
    const l = this.props.x * this.props.y;
    this.setState({
      bits: Array.from(Array(l)).map((v, i) => [
        i,
        rand(0, paths.length - 1),
        rand255(INITIAL_R - COLOR_VARIANCE, INITIAL_R + COLOR_VARIANCE),
        rand255(INITIAL_G - COLOR_VARIANCE, INITIAL_G + COLOR_VARIANCE),
        rand255(INITIAL_B - COLOR_VARIANCE, INITIAL_B + COLOR_VARIANCE),
      ]),
    });
  }

  componentDidMount() {
    this.intervalId = setInterval(() => requestAnimationFrame(this.swapBits), 1000);
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  swapBits = () => {
    const l = this.props.x * this.props.y;
    this.setState((state) => {
      const a = rand(0, l / 2 - 1);
      const b = rand(l / 2 + 1, l - 1);
      return {
        bits: state.bits
          .slice(0, a)
          .concat([state.bits[b]])
          .concat(state.bits.slice(a + 1, b))
          .concat([state.bits[a]])
          .concat(state.bits.slice(b + 1)),
      };
    });
  };

  render = () => {
    const { x, y } = this.props;
    return (
      <svg
        width="100%"
        viewBox={`0 0 ${x * SIZE_X} ${y * SIZE_Y}`}
        style={{ transformOrigin: '50% 50%', transform: 'rotateX(180deg)' }}
      >
        <g transform="translate(10 66)">
          {this.state.bits.map(([key, pathId, r, g, b], i) => {
            const [px, py, d] = paths[pathId];
            return (
              <g key={key} fill={`rgb(${r}, ${g}, ${b}`}>
                <path d={`M${(i % x) * SIZE_X + px} ${Math.floor(i / x) * SIZE_Y + py - BASE_Y} ${d}`} />)}
              </g>
            );
          })}
        </g>
      </svg>
    );
  };
}
